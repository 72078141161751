import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent } from './app-pages/error-page/error-page.component';
import { InvoicePaymentComponent } from './app-pages/invoice-payment/invoice-payment.component';


const routes: Routes = [
  {
    path: 'invoice-pay/:token/:accesscode',
    component: InvoicePaymentComponent
  },
  { path: '**', component: ErrorPageComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
