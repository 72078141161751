<div class="inv-content main-height content-bg-color">
    <div class="row">   
        <div class="col-md-12 col-lg-12 col-sm-12 inv-pay">
            <div class="card">
                <div class="row">
                    <div class="col-md-12">
                        <p style="font-size: 60px;">Sorry !!</p>
                    </div>
                    <div class="col-md-12">
                        <p> Either Your Redirection Link has expired or is an incorrect one to proceed. </p>
                        <p> Please try with a valid redirection link to continue.</p>
                    </div>
                </div>
    
            </div>
        </div>
    </div>
</div>