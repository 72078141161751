import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private http: HttpClient
  ) { }

  private urlPath = (window.location.origin.indexOf('localhost') >= 0) ? 'https://client-qa.moolapay.net/' : window.location.origin + '/';

  postRequest(api: string, requestBody?: any): Observable<any> {
    let option = {headers: new HttpHeaders().set('Content-type', 'application/json')};
    const body = JSON.stringify(requestBody);
    return this.http.post(this.urlPath + api, body, option)
    .pipe(map( res => {
      return res;
    }));
  }

  getRequest(api: string): Observable<any> {
    let option = {headers: new HttpHeaders().set('Content-type', 'application/json')};
    return this.http.get(this.urlPath + api, option)
    .pipe(map( res => {
      return res;
    }));
  }

  postFormData(api: string, formData?: FormData) {
    return this.http.post(this.urlPath + api, formData)
    .pipe(map( res => {
      return res;
    }));
  }

  // Common Function
  getDomainUrl() {
    return new Promise(resolve => {
      this.getRequest('auth/domain').subscribe(response => {
        if(response.status == 1 && response.data){
          var data = JSON.parse(JSON.stringify(response.data));
          data = JSON.parse(data);
          resolve(data);
        }
      })
    });
  }

  async applyThemes() {
    const fileref = document.createElement('link');
    fileref.setAttribute('rel', 'shortcut icon');
    fileref.setAttribute('type', 'assets/x-icon');
    const data: any = await this.getDomainUrl();
    document.title = data.title.concat(' | ').concat('Invoice Payment')
    document.getElementById('theme').setAttribute('href', data.cdnDomain + data.invoice);
    fileref.setAttribute('href', data.cdnDomain + data.favIcon);
    if (fileref !== undefined) {
      document.getElementsByTagName('head')[0].appendChild(fileref);
    }
  }
}
