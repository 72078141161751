<header>
    <div class="header-items">
        <div class="logo" [ngStyle]="{'background-image':' url(' + bgImage + ')'}"></div>
    </div>
</header>
<main>
    <div class="inv-content main-height content-bg-color" *ngIf="linkExpired == false">
        <div class="row" *ngIf="step == 1">
            <div class="col-md-6 col-lg-6 col-sm-12 left">
                <p class="header">
                    One step solution for your invoice payments.
                </p>
                <p class="sub-header">
                    Please click on proceed to pay to fill your details for making payment.
                </p>
            </div>
            <div class="col-md-6 col-lg-6 col-sm-12 right">
                <div class="card">
                    <div class="header">
                        <h3 style="text-align: left;">Your Invoice: </h3>
                        <p style="text-align: left;">Please confirm your details and proceed.</p>
                    </div>
                    <form [formGroup]="this.detailForm" action="">
                        <div class="row">
                            <div class="form-group col-md-12">
                                <label class="form-label" for="">Payment From:</label>
                                <input type="text" class="form-control" formControlName="client_name" disabled>
                            </div>
                            <div class="form-group col-md-12">
                                <label class="form-label" for="">Invoice Number:</label>
                                <input type="text" class="form-control" formControlName="invoice_number" disabled>
                            </div>
                            <div class="form-group col-md-12">
                                <label class="form-label" for="">Invoice Amount:</label>
                                <input type="text" class="form-control" formControlName="amount" disabled>
                            </div>
                            <div class="form-group col-md-12">
                                <label class="form-label" for="">Memo:</label>
                                <textarea name="" id="" cols="30" rows="3" class="form-control"
                                    formControlName="description" disabled></textarea>
                            </div>
                            <div class="form-group col-md-12">
                                <label class="form-label" for="">Payable To:</label>
                                <input type="text" class="form-control" formControlName="email_address" disabled>
                            </div>
                            <div class="col-md-12" style="text-align: right;">
                                <button class="btn btn-primary custom-bg" (click)="proceedToNext(2)"> Click to
                                    Proceed</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="step == 2">
            <div class="col-md-12 col-lg-12 col-sm-12 inv-pay">
                <div class="card">
                    <div style="padding: 0px 14px;">
                        <p class="header">Pay online with a bank account.</p>
                        <p class="sub-header">Enter your banking information to pay your invoice.</p>
                        <div class="invoice-detials">
                            <div>
                                <p><strong>Amount: </strong>${{this.detailForm.get('amount').value}}</p>
                            </div>
                            <div>
                                <p style="text-align: right;"><strong>Invoice Number:</strong>
                                    {{this.detailForm.get('invoice_number').value}}</p>
                            </div>
                        </div>
                    </div>

                    <form [formGroup]="this.paymentForm" action="">
                        <div class="row" style="margin: 0;">
                            <div class="form-group col-md-12">
                                <label class="form-label" for="">Account Holder Name:</label>
                                <input type="text" class="form-control" pattern="^[a-zA-Z !@#$,./?%^^&*()]+$" minlength="3" maxlength="50" formControlName='account_name'>
                                <small class="text-danger"
                                    *ngIf="this.paymentForm.get('account_name').invalid && 
                                    (this.paymentForm.get('account_name').dirty || this.paymentForm.get('account_name').touched)">
                                    Please enter a valid account name.
                                </small>
                            </div>
                            <div class="form-group col-md-6">
                                <label class="form-label" for="">Bank Routing Number:</label>
                                <input type="text" class="form-control" formControlName='routing_number' pattern="[0-9]{9,9}"  minlength="9" maxlength="9"
                                    (blur)="this.getBankRoutingDetails()">
                                <small class="text-danger"
                                    *ngIf="(this.paymentForm.get('routing_number').invalid || this.formErrors.validRouting) && 
                                    (this.paymentForm.get('routing_number').dirty || this.paymentForm.get('routing_number').touched || this.paymentForm.submitted )">
                                    Please enter a valid routing number.
                                </small>
                            </div>
                            <div class="form-group col-md-6">
                                <label class="form-label" for="">Bank Name:</label>
                                <input type="text" class="form-control" formControlName='bank_name'>
                            </div>
                            <div class="form-group col-md-6">
                                <label class="form-label" for="">Account Number:</label>
                                <input type="text" class="form-control" formControlName='account_number' pattern="^[0-9]+$" minlength="4" maxlength="20">
                                <small class="text-danger"
                                    *ngIf="this.paymentForm.get('account_number').invalid && 
                                    ( this.paymentForm.get('account_number').dirty || this.paymentForm.get('account_number').touched || this.paymentForm.submitted )">
                                    Please enter a valid Account Number.
                                </small>
                            </div>
                            <div class="form-group col-md-6">
                                <label class="form-label" for="">Re-enter Account Number:</label>
                                <input type="text" class="form-control" formControlName='confirm_account' pattern="^[0-9]+$">
                                <small class="text-danger"
                                    *ngIf="this.formErrors.accCompare">
                                    Entered account number is not matching.
                                </small>
                            </div>
                            <div class="form-group col-md-12">
                                <label class="form-label" for="myfile">Select a file:</label>
                                <input type="file" id="myfile" name="myfile" formControlName="myfile" class="form-control" accept="application/pdf"
                                    (change)="fileChangeEvent($event.target.files)" required>
                            </div>
                            <div class="form-group col-md-6">
                                <label class="form-label" for="">Account Type:</label>
                                <select class="form-select" formControlName="account_type">
                                    <option value="CHECKING">CHECKING</option>
                                    <option value="SAVING">SAVING</option>
                                </select>
                            </div>
                            <div class="form-group col-md-6">
                                <label class="form-label" for="">Phone Number:</label>
                                <input type="text" class="form-control" formControlName='phone_number' maxlength="14" mask="(000) 000-0000">
                                <small class="text-danger" *ngIf="this.paymentForm.get('phone_number').invalid && 
                                    ( this.paymentForm.get('phone_number').dirty || this.paymentForm.get('phone_number').touched || this.paymentForm.submitted )">
                                    Please enter a valid 10 digit number.
                                </small>
                            </div>
                            <div class="form-group col-md-6">
                                <label class="form-label" for="">Transaction Date:</label>
                                <input type="text" placeholder="MM/DD/YYYY" class="form-control" [minDate]="minDate" [bsConfig]="{ showWeekNumbers: false }" bsDatepicker formControlName='transaction_date' required>
                            </div>
                            <div class="col-md-12" style="text-align: right;">
                                <button class="btn btn-secondary" style="margin-right: 10px" (click)="cancelPayment()">Cancel</button>
                                <button class="btn btn-primary custom-bg" [ngClass]="{'loaderButton' : spinnerShow}" [disabled]="!this.paymentForm.valid" (click)="submitPayment()">
                                    <div *ngIf="spinnerShow" class="spinner-border" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                    Create Payment
                                </button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
        <div class="row" *ngIf="step == 3">
            <div class="col-md-12 col-lg-12 col-sm-12 inv-pay">
                <div class="card">
                    <div style="padding: 0px 14px;">
                        <p class="header success"> Payment Initiated. </p>
                        <p class="sub-header"> Your payment with below details have been successfully initiated. </p>
                        <ng-container>
                            <div class="invoice-detials" style="margin-top: 30px;">
                                <div>
                                    <p><strong>Amount: </strong>${{this.detailForm.get('amount').value}}</p>
                                </div>
                                <div>
                                    <p style="text-align: right;"><strong>Invoice Number:</strong>
                                        {{this.detailForm.get('invoice_number').value}}</p>
                                </div>
                            </div>
                            <div class="invoice-detials">
                                <div>
                                    <p><strong>Memo: </strong> {{this.detailForm.get('description').value}}</p>
                                </div>
                            </div>
                            <div class="invoice-detials">
                                <div>
                                    <p><strong>Payble To: </strong> {{this.detailForm.get('email_address').value}}</p>
                                </div>
                            </div>
                            <div class="invoice-detials">
                                <div>
                                    <p> </p>
                                </div>
                                <div>
                                    <!-- <p><strong>Date: </strong> mm/dd/yyyy</p> -->
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="step == 4">
            <div class="col-md-12 col-lg-12 col-sm-12 inv-pay">
                <div class="card">
                    <div style="padding: 0px 14px;">
                        <p class="header error"> Transaction Failed
                        </p>
                        <p class="sub-header"> Your transaction with below details could not be completed. Please try
                            again in sometime</p>
                        <ng-container>
                            <div class="invoice-detials" style="margin-top: 30px;">
                                <div>
                                    <p><strong>Amount: </strong>${{this.detailForm.get('amount').value}}</p>
                                </div>
                                <div>
                                    <p style="text-align: right;"><strong>Invoice Number:</strong>
                                        {{this.detailForm.get('invoice_number').value}}</p>
                                </div>
                            </div>
                            <div class="invoice-detials">
                                <div>
                                    <p><strong>Memo: </strong> {{this.detailForm.get('description').value}}</p>
                                </div>
                            </div>
                            <div class="invoice-detials">
                                <div>
                                    <p><strong>Payble To: </strong> {{this.detailForm.get('email_address').value}}</p>
                                </div>
                            </div>
                            <div class="invoice-detials">
                                <div>
                                    <p> </p>
                                </div>
                                <div>
                                    <!-- <p><strong>Date: </strong> mm/dd/yyyy</p> -->
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="inv-content error-height content-bg-color" *ngIf="linkExpired == true">
        <app-link-expiry></app-link-expiry>
    </div>

</main>

