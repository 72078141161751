import { Component, OnInit } from '@angular/core';
import { AppService } from './app-service/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'invoice-app';
  isLinkElementLoaded = false;

  constructor( private service: AppService) {
    this.service.applyThemes();
  }

  ngOnInit() {
  }

}
