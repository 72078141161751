import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AppService } from 'src/app/app-service/app.service';

@Component({
  selector: 'app-invoice-payment',
  templateUrl: './invoice-payment.component.html',
  styleUrls: ['./invoice-payment.component.scss']
})
export class InvoicePaymentComponent implements OnInit {
  step = 1;
  token: string;
  accessCode: string;
  detailForm: FormGroup;
  paymentForm: FormGroup;
  linkExpired: boolean;
  routingDetails: any;
  fileContent: any;
  bgImage: string;
  minDate: any = new Date()
  spinnerShow: boolean = false;
  formErrors = {isError: false, accCompare: null, validRouting: null}
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private service: AppService
  ) { 
    this.getLogoUrl();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.token = params.get('token');
      this.accessCode = params.get('accesscode');
      if (this.token && this.accessCode) {
        this.validateUrlToken(this.token, this.accessCode);
      }
    });
  }

  getLogoUrl = async() => {
    const data: any = await this.service.getDomainUrl();
    this.bgImage = data.cdnDomain.concat(data.logo)
  }

  validateUrlToken(token, accessCode) {
    // Call an api to validate.
    const body = {
      token: this.token,
      access_code: this.accessCode
    }
    this.service.postRequest('recepint/invoice/verify/token', body).subscribe((res: any) => {
      if (res && res.status === 1) {
        this.linkExpired = false;
        this.initiateDetailsForm(res.data);
      } else {
        this.linkExpired = true;
      }
    }, err => {
      this.linkExpired = true;
    });
  }

  initiateDetailsForm(data) {
    this.detailForm = this.fb.group({
      amount: [data.amount.toFixed(2), []],
      client_name: [data.client_name, []],
      description: [data.description, []],
      email_address: [data.email_address, []],
      invoice_number: [data.invoice_number, []]
    });
  }

  getBankRoutingDetails() {
    const body = {
      token: this.token,
      access_code: this.accessCode,
      routing_number: this.paymentForm.controls.routing_number.value
    }
    this.service.postRequest('recepint/invoice/bank-routing-details', body).subscribe((res: any) => {
      if (res && res.status === 1) {
        this.paymentForm.get('bank_name').setValue(res.data.bank_name, {onSelf: true});
        this.formErrors.isError = false;
        this.formErrors.validRouting = null;
      } else {
        this.paymentForm.get('bank_name').setValue(null, {onSelf: true});
        this.formErrors.isError = true;
        this.formErrors.validRouting = "Please enter a valid routing number";
      }
    }, err => {
      this.linkExpired = true;
    });
  }

  proceedToNext(step) {
    if (step === 2) {
      this.initiatePaymentForm();
    }
    this.step = step;
  }

  initiatePaymentForm() {
    this.paymentForm = this.fb.group({
      account_name: ['', [Validators.required]],
      account_number: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
      confirm_account: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(20)]],
      account_type: ['', [Validators.required]],
      bank_name: [{value: '',disabled: true}, [Validators.required]],
      routing_number: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(9)]],
      token: [this.token, [Validators.required]],
      access_code: [this.accessCode, [Validators.required]],
      myfile: ['', [Validators.required]],
      phone_number: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      transaction_date: ['', [Validators.required]]
    });

    this.initiateFormValidation();
  }

  initiateFormValidation() {
    this.paymentForm.get('account_number').valueChanges.subscribe(acc => {
      if (acc !== this.paymentForm.controls.confirm_account.value) {
        this.formErrors.isError = true;
        this.formErrors.accCompare = 'Please enter a valid account number.';
      } else {
        this.formErrors.isError = false;
        this.formErrors.accCompare = null;
      }
    });

    this.paymentForm.get('confirm_account').valueChanges.subscribe(conf => {
      if (conf !== this.paymentForm.controls.account_number.value) {
        this.formErrors.isError = true;
        this.formErrors.accCompare = 'Please enter a valid account number.';
      } else {
        this.formErrors.isError = false;
        this.formErrors.accCompare = null;
      }
    });
  }

  fileChangeEvent(e: any) {
    this.fileContent = e[0];
  }

  submitPayment() {
    if (this.paymentForm.valid) {
      this.spinnerShow = true;
      let payControls = this.paymentForm.controls;
      const date = new Date(payControls.transaction_date.value);
      const formData = new FormData();
      formData.append('file', this.fileContent);
      formData.append('account_name',  payControls.account_name.value.trim());
      formData.append('account_number', payControls.account_number.value.trim());
      formData.append('account_type', payControls.account_type.value);
      formData.append('routing_number', payControls.routing_number.value.trim());
      formData.append('token', payControls.token.value.trim());
      formData.append('access_code', payControls.access_code.value.trim());
      formData.append('phone_number', payControls.phone_number.value.trim());
      formData.append('transaction_date', date.getTime().toString());
      this.service.postFormData('recepint/invoice/upload', formData).subscribe((res: any) => {
        this.spinnerShow = false;
        if (res && res.status === 1) {
          this.step = 3; // Success
        }  else {
          this.step = 4; // Failure
        }
      }, err => {
        this.step = 4;
        this.spinnerShow = false;
      });
    }
  }

  cancelPayment() {
    this.step = 4;
  }


}
